import React from "react"
import { graphql } from "gatsby"
import Layout from "components/layout"
import SEO from "components/SEO"
import { FooterConfig } from "components/Footer"
import ModuleRender from "components/ModuleRender"
import { HeaderConfig } from "components/Header"
import { HeaderVariant } from "components/Header/types"

export default function Page({ data }) {
	const page = data.contentfulPage

	if (!page) {
		return null
	}
	const { sections, seo, hideFooterDemo, transparentHeader, headerVariant, footerVariant } = page

	const footerConfig: FooterConfig = React.useMemo(() => {
		return {
			hideDemo: Boolean(hideFooterDemo),
			hideNewsletter: false,
			variant: footerVariant
		}
	}, [hideFooterDemo, footerVariant])

	const headerConfig: HeaderConfig = React.useMemo(() => {
		return {
			variant: transparentHeader ? HeaderVariant.TransparentWhite : headerVariant || HeaderVariant.Default,
		}
	}, [transparentHeader, headerVariant])

	return (
		<>
			<SEO {...seo} />
			<Layout footerConfig={footerConfig} headerConfig={headerConfig}>
				<ModuleRender sections={sections} />
			</Layout>
		</>
	)
}

export const query = graphql`
	query {
		contentfulPage(slug: { eq: "home" }) {
			hideFooterDemo
			transparentHeader
			headerVariant
			footerVariant
			seo {
				...SEO
			}
			sections {
				... on ContentfulAlternatingCards {
					...AlternatingCards
				}
				... on ContentfulBrandImagesSection {
					...BrandImages
				}
				... on ContentfulBrandImagesWithReviews {
					...BrandImagesWithReviews
				}
				... on ContentfulLandingSections {
					...Section
				}
				... on ContentfulReferencesSection {
					...ReferencesSection
				}
				... on ContentfulEmbedSpotifySection {
					...EmbedSpotifySection
				}
				... on ContentfulTimeline {
					...Timeline
				}
				... on ContentfulForm {
					...Form
				}
                ... on ContentfulBrandCategorySection {
                    ...BrandCategorySection
                }
			}
		}
	}
`
